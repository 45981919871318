@import '../../Theme.scss';

#tasks-page{
    .button{
        margin-top:2em;
        display: flex;
        .comp-btn{
            &.full {flex-grow: 1; margin-left: 1em;}
            &:not(.full){min-width: 3em;}
            padding: 1em
        }
        text-align: right;
    }

    .tasks{
        .all-done{
            text-align: center;
            color: $accent;
        }

        .completed, .not-completed{
            display: grid;
            grid-gap: 5px;
        }

        .completed-toggle{
            margin: 1.5em 0em 1em 0em;
            padding: .5em 1em;
            display: flex;
            align-items: center;
            font-size: .85em;
            
            &.rotate svg{
                transform: rotate(90deg);
            }
            svg{
                transition: .5s;
                margin-right: .5em;
            }
        }

        .comp-task-row-card{
            background-color: $widget-bg;
            border-radius: $radius;
            box-shadow: $shadow-1;
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 0em 1em;

            .status{
                margin-right: 1em;
                display: inline-flex;
                align-items: center;
                opacity: 0.15;
                cursor: pointer;
                @include noselect;
                &.done{
                    opacity: 1;
                }
                svg{
                    font-size: 1.2em;
                }
            }

            .title{
                flex-grow: 1;
                padding: 1em 0em;
                cursor: pointer;
                @include noselect;
            }

            .tags{
                display: flex;
                align-items: center;
                .tag{
                    padding: .3em .5em;
                    font-size: .7em;
                    font-weight: bold;
                }
            }
        }
    }

    .task-detail{
        input{background-color: transparent}
        .title{
            input{
                padding-left:.2em;
                font-size: 1.2em;
            }
        }

        .subtasks{
            display: flex; flex-direction: column; gap: .7em; 
            padding: 1em .5em;

            .subtask{
                display: flex; gap: 1em;
                padding: .1em 0em;

                
                .icon{
                    display: flex;
                    justify-content: center; align-items: center;
                    cursor: pointer;
                    svg{font-size: 1.1em;}
                    &.status{
                        opacity: .3;
                    }
                }

                &.done{
                    .status{opacity: 1;}
                    input{text-decoration: line-through;}
                } 

                .input{
                    flex-grow: 1;
                }

                input{
                    width: 100%;
                    outline:none;
                    color: $primary-tx;
                    border: none;
                    font-family: inherit; font-size: inherit;
                }
            }
            
        }
    }
}