@import "../../Theme.scss";

.comp-modal {
    position: fixed;
    padding: 1em;
    background-color: $secondary-bg;
    box-shadow: $shadow-1;

    .top {
        display: flex;
        justify-content: space-between;
        margin-bottom: 1em;
        font-size: 1.2em;
        .close{
            cursor: pointer;
            @include noselect;
            svg {
            font-size: 1.2em;
            }
        }
        
    }

    bottom: 0;
    left: 0;
    width: calc(100% - 2em);

    transform: translateY(100%);
    transition: 0.5s;
    &.open {
        transform: unset;
    }
}
