@import "./Theme.scss";

* {
    scroll-behavior: smooth;
}

html, body, #root, #app {
    position: relative;
    padding: 0px;
    margin: 0px;
    font-family: Raleway, sans-serif;
    background-color: $primary-bg;
    color: white;
    min-height: 100vh;
    min-height: calc(100 * var(--vh));
    font-size: 20px;
    @media (max-width: 1200px) {
        font-size: 18px;
    }
    @media (max-width: 600px) {
        font-size: 16px;
    }
}

#app {
    display: flex;
    flex-direction: column;
}

.page {
    flex-grow: 1;
}

main {
    padding: 2em;
    max-width: 1200px;
    margin: auto;
    @media (max-width: $mobile) {
        padding:1em .5em;
    }
}

h1 {
    font-size: 2em;
    padding:0em .3em;
}
h2 {
    font-size: 1.5em;

    subh,
    .subh {
        font-size: 0.6em;
        color: $accent;
    }
}
h3 {
    font-size: 1.3em;
}
h4 {
    font-size: 1.2em;
    font-weight: 600;
    margin: 0em 0em .5em;
}
h5 {
    font-size: 1.1em;
    font-weight: 600;
    margin: 0em 0em .5em;
}
h6 {
    font-size: 0.7em;
    margin: 0em 0em 0.3em 0em;
    font-weight: 700;
    text-transform: uppercase;
}

h1, h2, h3, h4, h5 {
    color: $accent;
    font-family: "Jua";
    font-weight: 800;
    position: relative;
    margin: 0em 0em 0.5em 0em;

    &.underline::before {
        height: 0.06em;
        border-radius: 3px;
        width: 1.8em;
        position: absolute;
        bottom: -0.15em;
        background-color: $accent;
        content: " ";
    }

    &.center {
        text-align: center;
    }

    &.center::before {
        left: 50%;
        transform: translateX(-50%);
    }
}

h1.center + p,
h2.center + p,
h3.center + p {
    text-align: center;
    margin-bottom: 2em;
    font-weight: 500;
}
