@import '../../Theme.scss';

.inputWrapper{
    width:100%;
    position: relative;

    input{
        box-sizing: border-box;
        width: calc(100% - .6em);
        margin: 0em;
        font-family: inherit;
        display:block;
        color: $primary-tx;
        padding: 1.6em .3em .5em;
        display: block;
        font-size: 1em;
        border: none;
        background-color: $widget-bg;
        border-bottom: 1px solid $neutral;
        outline: none;
        width: 100%;
        
        &.nolabel{
            padding: .5em;
        }

        &::placeholder{
            color: #0000;
        }

        &:focus::placeholder, &.nolabel::placeholder{
            color: #777;
        }

       
    }

    &.invalid input{
        border-color:$danger;
    }

    &:focus-within input{
        border-color: $accent;
    }

    .label{
        position: absolute;
        top: 1.1em;
        left: .3em;
        pointer-events: none;
        transition: .2s ease all;
    }

    input:required ~ .label::after{
        content:" *";
        font-size: smaller;
    }

    input:not(:placeholder-shown) ~ .label, input:focus ~ .label{
        top: .6em; left: .3em;
        font-size: .7em;
        font-weight: 600;
    }

   
}  

.validate{
    .inputWrapper{
        input:invalid{
            border-color: $danger;
        }
    }
}