@import '../../Theme.scss';

.comp-fab{
    @include noselect;
    position: fixed;
    bottom: 2em;
    right: 2em;
    width: 3.5em;
    height: 3.5em;
    background-color: $accent;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    cursor: pointer;

    svg{
        font-size: 2em;
    }
}