@import '../../Theme.scss';

.comp-btn{
    font-size: 1em;
    min-width: 6em;
    padding: .5em 1em;
    border:2px solid $accent;
    color:inherit;
    border-radius: 4px;
    cursor: pointer;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    position: relative;

    user-select: none;
    -moz-user-select: none;
    -khtml-user-select: none;
    -webkit-user-select: none;
    -o-user-select: none;

    &:hover{
        background-color: #FFF2;
    }

    &.full{
        background-color: $accent;
        font-weight: bold;
        color: $accent-tx;
        padding: calc(.5em + 2px) calc(1em + 2px);
        border: none;
        
        &:hover{
            //;background-color: lighten($accent, 10);
            //border-color: lighten($accent, 10);
        }

        &::before{
            background-color: #0003;
        }


    }   

    &.danger{
        background-color: $danger;
        color: white;
        &:hover{
            //background-color: lighten($error, 10);
            //border-color: lighten($error, 10);
        }
    }

    overflow: hidden;

    &::before{
        position: absolute;
        background-color: #FFF2;
        content: "";
        border-radius: 1em;
    }

    &.animate:before{
        
        animation: .4s riple ease-out infinite;
    }
}

@keyframes riple{
    0%{
        top:0%;
        left:50%;
        width:0%;
        height: 100%;
    }
    100%{
        height: 100%;
        left:-50%;
        top:0%;
        width:200%;
    }
}


@-webkit-keyframes riple{
    0%{
        top:0%;
        left:50%;
        width:0%;
        height: 100%;
    }
    100%{
        height: 100%;
        left:-50%;
        top:0%;
        width:200%;
    }
}