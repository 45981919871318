@import url('https://fonts.googleapis.com/css2?family=Jua&family=Nunito:ital,wght@0,300;0,400;0,600;0,700;0,800;0,900;1,300;1,400;1,600;1,700;1,800;1,900&family=Raleway:ital,wght@0,200;0,300;0,400;0,500;0,600;0,800;0,900;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

$mobile: 600px;
$tablet: 1200px;

$accent: var(--accent);
$primary-bg: var(--pimary-bg);
$primary-tx: var(--primary-tx);
$secondary-bg: var(--secondary-bg);
$secondary-tx: var(--secondary-tx);
$widget-bg: var(--widget-bg);
$danger: var(--danger);
$neutral: var(--neutral);
$accent-tx: var(--accent-tx);

.dark-theme{
    --accent: #61dafb;
    --pimary-bg: #282c34;
    --primary-tx: #FFF;
    --secondary-bg: #303641;
    --secondary-tx: #FFF;
    --widget-bg: #1b1e23;
    --danger: red;
    --neutral: #454545;
    --accent-tx: #fff;
}

.light-theme{
    --accent: #0094bd;
    --pimary-bg: white;
    --primary-tx: #000;
}

$radius: 4px;



$shadow-1: 0px 0px 8px 4px #00000010;
$shadow-2: 0px 0px 8px 4px #00000020;
$shadow-3: 0px 0px 8px 4px #00000030;


@mixin noselect {
    -webkit-touch-callout: none; /* iOS Safari */
      -webkit-user-select: none; /* Safari */
       -khtml-user-select: none; /* Konqueror HTML */
         -moz-user-select: none; /* Old versions of Firefox */
          -ms-user-select: none; /* Internet Explorer/Edge */
              user-select: none; /* Non-prefixed version, currently
                                    supported by Chrome, Edge, Opera and Firefox */

                                    -webkit-tap-highlight-color:transparent
  }